// @flow

/**
 * Module dependencies.
 */

import { color, units } from '@seegno-labs/react-components/styles';
import { theme } from 'styled-tools';
import UnStyledRawHtml from '@seegno-labs/react-components/raw-html';
import styled, { css } from 'styled-components';

/**
 * Raw styles.
 */

const rawStyles = css`
  a {
    color: inherit;
    text-decoration: underline;

    &:focus,
    &:hover {
      color: ${color('primary')};
      transition: color ${theme('transitions.default')};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  small {
    margin: 0 0 ${units(2)};
    padding: 0;
  }
`;

/**
 * `TextEditor` styled component.
 */

const TextEditor = styled(UnStyledRawHtml).attrs({ element: 'div' })`
  ${rawStyles}

  h1 {
    ${theme('typography.styles.h1')}

    color: ${color('primary')};
  }

  h2 {
    ${theme('typography.styles.h2')}

    color: ${color('primary')};
  }

  h3 {
    ${theme('typography.styles.h3')}

    color: ${color('primary')};
  }

  h4 {
    ${theme('typography.styles.h4')}

    color: ${color('primary')};
  }

  h5 {
    ${theme('typography.styles.h5')}

    color: ${color('primary')};
  }

  h6 {
    ${theme('typography.styles.h6')}

    color: ${color('primary')};
  }

  p {
    ${theme('typography.styles.p')}
  }

  small {
    ${theme('typography.styles.small')}
  }
`;

/**
 * Export `RawHtml` component.
 */

export const RawHtml = styled(UnStyledRawHtml).attrs({ element: 'div' })`
  ${rawStyles}
`;

/**
 * Export `TextEditor` component.
 */

export default TextEditor;
