// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import type { TemplateProps } from 'types/template';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, themeProp, units } from '@seegno-labs/react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'components/core/images/background-image';
import FooterIconsSection from 'components/footer/footer-icons-section';
import PageContainer from 'components/page-container';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import TextEditor from 'components/core/text-editor';
import bulletIcon from 'assets/svg/lists-arrow.svg';
import styled from 'styled-components';

/**
 * Legal page query.
 */

const legalPageQuery = graphql`
  query {
    data: allSeegnoCmsPayspaceTermsOfServicePage {
      nodes {
        content {
          footerSection {
            buttonLabel
            buttonUrl
            leftIcon {
              file {
                publicURL
              }
            }
            leftText
            rightIcon {
              file {
                publicURL
              }
            }
            rightText
          }
        }
      }
    }
    image: file(relativePath: { eq: "images/shapes@2x.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

/**
 * `Section` styled component.
 */

const Section = styled.section`
  padding: ${units(26)} 0 ${units(20)};
  position: relative;
`;

/**
 * `StyledTextEditor` styled component.
 */

const StyledTextEditor = styled(TextEditor)`
  p {
    margin: 0 0 ${units(2)};
  }

  small {
    margin: 0 0 ${units(2)};
  }

  h1,
  h4,
  h5,
  h6 {
    color: ${color('primary')};
  }

  h2,
  h3 {
    color: ${color('secondary')};
  }

  h1,
  h2,
  h3 {
    margin-bottom: ${units(4)};

    ${media.min('ms')`
      margin-bottom: ${units(6)};
    `}
  }

  h4,
  h5,
  h6 {
    margin-bottom: ${units(2)};

    ${media.min('ms')`
      margin-bottom: ${units(3)};
    `}
  }

  ul {
    padding: ${units(2.5)} 0;

    ${media.min('ms')`
      padding: ${units(5)} 0;
    `}

    > li {
      margin-bottom: ${units(2)};
      padding-left: ${units(2)};
      position: relative;

      &::before {
        background-image: url('data:image/svg+xml;utf8,${encodeURIComponent(bulletIcon)}');
        content: '';
        height: ${units(1.5)};
        left: 0;
        position: absolute;
        top: 5px;
        width: ${units(1.5)};
      }

      ${media.min('ms')`
        margin-bottom: ${units(3)};
        padding-left: ${units(4)};

        &::before {
          left: ${units(1)};
        }
      `}
    }
  }

  ol {
    counter-reset: item;
    padding-inline-start: 0;

    > li::before {
      ${themeProp('typography.styles.h3')}

      color: ${color('secondary')};
    }

    > li > p,
    > li > ol {
      padding-top: ${units(4)};
    }

    li {
      counter-increment: item;
      display: table;
      margin-bottom: ${units(3)};

      > h3 {
        display: inline;
      }

      &::before {
        content: counters(item, ".") ". ";
        display: inline;
        padding-right: 0;
      }
    }

    ol > li::before {
      ${themeProp('typography.styles.p')}

      color: ${color('grey700')};
    }

    ${media.min('ms')`
      padding-inline-start: ${units(5)};

      > li > p,
      > li > ol {
        padding-top: 0;
      }

      li {
        > h3 {
          display: block;
        }

        &::before {
          display: table-cell;
          padding-right: ${units(1)};
        }
      }
    `}
  }

  dl {
    dd,
    dt {
      display: inline;
      margin: 0;
    }

    dt {
      font-weight: 500;
      margin-left: -1.5em;
    }

    dd {
      &::after {
        content: '';
        display: block;
        margin-bottom: ${units(2)};
      }
    }
  }
`;

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 85vh;

  &,
  &::after,
  &::before {
    background-position: bottom center !important;
  }
`;

/**
 * `Legal` container.
 */

const Legal = ({ pageContext }: TemplateProps): Node => {
  const metatags = pageContext?.metatags;
  const { content, lead, title } = pageContext?.content;
  const { data, image } = useStaticQuery(legalPageQuery);
  const dataContent = data?.nodes[0]?.content;

  return (
    <PageContainer
      footerElement={<FooterIconsSection {...dataContent?.footerSection} />}
      metatags={metatags}
    >
      <Section>
        <StyledBackgroundImage fluid={image?.childImageSharp?.fluid} />

        <Container>
          <Grid>
            <Box
              gridColumn={'1 / -1'}
              gridColumnMs={'2 / -2'}
              textAlign={'center'}
            >
              <Type.H1
                color={color('secondary')}
                marginBottom={units(10)}
              >
                {title}
              </Type.H1>
            </Box>

            <Box
              gridColumn={'1 / -1'}
              gridColumnLg={'3 / -3'}
              gridColumnMs={'2 / -2'}
              marginBottom={units(20)}
            >
              <RawHtml element={Type.H5}>
                {lead}
              </RawHtml>
            </Box>
          </Grid>
        </Container>

        <Container>
          <Grid>
            <Box
              gridColumn={'1 / -1'}
              gridColumnLg={'3 / -3'}
              gridColumnMs={'2 / -2'}
            >
              <StyledTextEditor>
                {content}
              </StyledTextEditor>
            </Box>
          </Grid>
        </Container>
      </Section>
    </PageContainer>
  );
};

/**
 * Export `Legal` container.
 */

export default Legal;
